import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function DashboardUPAS(props: any) {
  // const [token, setToken] = useState('');
  
  const DataUPAS = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="538"
          src="https://lookerstudio.google.com/embed/reporting/35d860f8-d1a5-4974-b540-83f847ec7228/page/p_ltasbqz2rc"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };
  return (
    <div>
      <ContentHeader title="Dashboard Angkutan Sekolah" />
      <div className="card card-outline card-primary" style={{ width:'100%',height:'100vh'}}>
        <div className="card-header">
          <h3 className="card-title"> Angkutan Sekolah</h3>
        </div>
        <div className="card-body">
          <DataUPAS/>
        </div>
      </div>
    </div>
  );
}

export default DashboardUPAS;
