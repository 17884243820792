import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function DashboardNataru(props: any) {
  // const [token, setToken] = useState('');
  const Nataru2022 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="538"
          src="https://lookerstudio.google.com/embed/reporting/5f63e24f-5e44-4c9e-aab7-c11aac8b0624/page/p_ydo20n2euc"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  return (
    <div>
      <ContentHeader title="Dashboard Nataru" />
      <div className="card card-outline card-primary" style={{ width:'100%',height:'100vh'}}>
      <div className="card-header border-0 ui-sortable-handle"  style={{cursor: 'move'}}>
        <h3 className="card-title">Angkutan Natal dan Tahun Baru 2022</h3>
        <div className="card-tools">
        <button type="button" className="btn btn-primary btn-sm" data-card-widget="collapse" title="Collapse">
        <i className="fas fa-minus"></i>
        </button>
        </div>
        </div>
        <div className="card-header">
          <h3 className="card-title"> </h3>
        </div>
        <div className="card-body">
        <Nataru2022 />
        </div>
      </div>
    </div>
  );
}

export default DashboardNataru;
