import { SmallBox } from "@app/components";
import React, { useState } from "react";
import { ContentHeader } from "@components";
// import minioClient from "../minioConfig";
import * as Minio from 'minio';

const minioClient = new Minio.Client({
  endPoint: '192.168.60.43',
  port: 9000,
  useSSL: false,
  accessKey: 'AUZUNsaxB3GzZ2vaYIOa',
  secretKey: 'BXQAuhomUAWZ9u7xNxaXykRnwWIBq6rBCsZGxV7t',
});



function FileUpload(props: any) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // State untuk menyimpan file yang dipilih
  const [fileName, setFileName] = useState(""); // State untuk menyimpan nama file
  const [category, setCategory] = useState("");
  const [jenis, setJenis] = useState("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv'
      ) {
        setSelectedFile(file);
        setFileName(file.name);
      } else {
        // Menampilkan pesan kesalahan jika file bukan Excel atau CSV
        alert('Hanya file format Excel (XLS, XLSX) dan CSV yang diperbolehkan!');
        event.target.value = ''; // Menghapus nilai file input
        setSelectedFile(null);
        setFileName('');
      }
    }
  };

  interface SubCategoryOptions {
    value: string;
    label: string;
  }

  interface CategoryOptions {
    [key: string]: SubCategoryOptions[];
  }

  const categories = [
    { value: "dalops", label: "Dalops" },
    { value: "other", label: "Other" }
  ];
  const Jenis = [
    { value: "harian", label: "Harian" },
    { value: "mingguan", label: "Mingguan" },
    { value: "bulanan", label: "Bulanan" }
  ];

  const subCategories: CategoryOptions = {
    dalops: [
      { value: "penindakan", label: "Penindakan" },
    ],
    other: [
      { value: "other", label: "Lainnya" },
    ],
  };


  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();// Lakukan pengiriman file atau tindakan lainnya di sini
    if (!selectedFile) {
      alert('Silakan pilih file yang akan diunggah.');
      return;
    }
    if (!jenis || !category) {
      alert('Silakan pilih jenis laporan dan kategori.');
      return;
    }
    const selectedSubCategory = subCategories[category]?.find(
      (option) => option.value === category
    );

    if (!selectedSubCategory) {
      alert('Silakan pilih subkategori.');
      return;
    }
    //console.log(selectedFile); // Contoh: Menggunakan selectedFile dalam tindakan
    //console.log(fileName);
    try {
      const objectName = selectedFile.name;
      const filePath = `${selectedSubCategory.value}/${jenis}/${objectName}`;
      const bucketName = 'dallops';
      await minioClient.fPutObject(bucketName, objectName, filePath);
      //console.log('File berhasil diunggah.');
    } catch (error) {
      console.error('Terjadi kesalahan saat mengunggah file:', error);
    } // Contoh: Menggunakan fileName dalam tindakan
  };





  return (
    <div>

      <ContentHeader title="Data Jaklingko Indonesia" />
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">Upload File</h3>
        </div>


        <form>
          <div className="card-body">
            <div className="form-group">
              <label>Jenis Laporan</label>
              <select
                className="form-control"
                value={jenis}
                onChange={(e) => setJenis(e.target.value)}
              >
                <option value="">Pilih Jenis Laporan</option>
                {Jenis.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                {categories.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Sub-Category</label>
              <select className="form-control">
                <option value="">Select Sub-Category</option>
                {subCategories[category]?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Choose File</label>
              <div className="input-group">
                <div className="custom-file">
                  <input type="file" className="custom-file-input" id="exampleInputFile" onChange={handleFileChange} />
                  <label className="custom-file-label" htmlFor="exampleInputFile" >{fileName || "Choose File"}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FileUpload;


