import { SmallBox } from "@app/components";
import React, { useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import axios, { AxiosResponse, AxiosError } from 'axios';
import { getIn } from "formik";


const getTkn = 'https://portaldata.jaktraffic.my.id/pqs/baj/angleb23/kupat';
const API_URL = 'https://portaldata.jaktraffic.my.id/pqs/baj/angleb23/Tlontong';

function DetailAngleb2023() {
  const [token, setToken] = useState('');
  const [sterminal, setSterminal] = useState([]);
  const [sarteri, setSarteri] = useState([]);
  const [dalkot, setDalkot] = useState([]);
  const [tol, setTol] = useState([]);
  const [pelabuhan, setPelabuhan] = useState([]);
  const [penerbangan, setPenerbangan] = useState([]);
  const [rcheck, setRcheck] = useState([]);
  const [kajj, setKajj] = useState([]);
  const [saum, setAum] = useState([]);

  const getSummaryTerminal = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      ////console.log(responseSummary);
      setSterminal(responseSummary.data.SummaryTerminal);
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryKendaraanArteri = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setSarteri(responseSummary.data.SummaryKendaraanArteri);
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryKendaraanDalkot = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setDalkot(responseSummary.data.SummaryKendaraanDalkot);
    } catch (error) {
      console.error(error);
    }
  };
  const getSummaryKendaraanTol = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setTol(responseSummary.data.SummaryKendaraanTol);
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryPelabuhan = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setPelabuhan(responseSummary.data.SummaryPelabuhan);
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryPenerbangan = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setPenerbangan(responseSummary.data.SummaryPenerbangan);
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryRampCheck = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setRcheck(responseSummary.data.SummaryRampCheck);
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryKAJJ = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setKajj(responseSummary.data.SummaryKAJJ);
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryAUM = async () => {
    try {
      const responseSummary = await axios.get(API_URL, {

        headers: {
          'pdtn-token-angleb23': token,
        }
      });
      //console.log(responseSummary);
      setAum(responseSummary.data.SummaryAUM);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const getToken = () => {
      // Fetch API untuk mendapatkan token
      axios.get(getTkn)
        .then((response) => {
          setToken(response.data.token);
          //console.log(response.data.token);
        });
    }
    getToken();
  }, []);

  useEffect(() => {
    if (token) {
      getSummaryTerminal();
      getSummaryKendaraanArteri();
      getSummaryKendaraanDalkot();
      getSummaryKendaraanTol();
      getSummaryPelabuhan();
      getSummaryPenerbangan();
      getSummaryRampCheck();
      getSummaryKAJJ();
      getSummaryAUM();
    }
  }, [token]);

  return (
    <div>
      <div className="row">
        <div className="buttonBack">
          <a href="/dataproduksiterminal">
            <button
              type="button"
              className="btn bg-gradient btn-secondary btn-sm mb-3 ml-1"
            >
              Kembali
            </button>
          </a>
        </div>
      </div>
      <ContentHeader title="Detail Summary Angkutan Lebaran 2023" />
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary Terminal</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Terminal</th>
                  <th>Akumulasi Penumpang Hingga Hari Ini</th>
                  <th>Jumlah Penumpang Hari Ini</th>
                  <th>Rata-rata Penumpang Week days pada hari-hari Normal</th>
                  <th>Rata-rata Penumpang Week ends pada hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Perbandingan Lonjakan dengan Masa Angleb 2022 (%)</th>
                  <th>Penumpang Berangkat</th>
                  <th>Penumpang Tiba</th>
                  <th>Perbandingan Jumlah Penumpang</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {sterminal && sterminal.length > 0 && sterminal.map((Sumter, index) => (
                  <tr key={index}>
                    <td>{Sumter.Terminal}</td>
                    <td>{Sumter.Akum_Pnp_DoD}</td>
                    <td>{Sumter.Tot_Pnp_D}</td>
                    <td>{Sumter.Avg_PnpWeekday_YcY}</td>
                    <td>{Sumter.Avg_PnpWeekend_YcY}</td>
                    <td>{Sumter.Lonjakan} %</td>
                    <td>{Sumter.Comp_LY_Lonjakan} %</td>
                    <td>{Sumter.Pnp_Dep}</td>
                    <td>{Sumter.Pnp_Arr}</td>
                    <td>{Sumter.Pnp_LY}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary Kendaraan Arteri</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Ruas Jalan</th>
                  <th>Akumulasi Volume Lalin Hingga Hari Ini</th>
                  <th>Volume Lalu Lintas Hari Ini</th>
                  <th>Rata-rata Volume Harian pada Hari-hari Normal</th>
                  <th>Rata-rata Volume Harian Weekends pada Hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Perbandingan Lonjakan dengan Masa Angleb 2022 (%)</th>
                  <th>Volume Lalu Lintas Tahun Lalu</th>
                  <th>Masuk</th>
                  <th>Keluar</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {sarteri && sarteri.length > 0 && sarteri.map((Arteri, index) => (
                  <tr key={index}>
                    <td>{Arteri.Ruas_Jalan}</td>
                    <td>{Arteri.Akum_Vol_Lalin_DcD}</td>
                    <td>{Arteri.Vol_Lalin_today}</td>
                    <td>{Arteri.Avg_Vol_HarianNormal}</td>
                    <td>{Arteri.Avg_Vol_HarianWeekend}</td>
                    <td>{Arteri.Lonjakan_HariNormal} %</td>
                    <td>{Arteri.Comp_LY_Lonjakan} %</td>
                    <td>{Arteri.Vol_Lalin}</td>
                    <td>{Arteri.Masuk}</td>
                    <td>{Arteri.Keluar}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary Kendaraan Dalkot</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Kategori Kendaraan</th>
                  <th>Akumulasi Volume Lalin Hingga Hari Ini</th>
                  <th>Volume Lalu Lintas Hari Ini</th>
                  <th>Rata-rata Volume Harian Weekdays pada Hari-hari Normal</th>
                  <th>Rata-rata Volume Harian Weekends pada Hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Perbandingan Lonjakan dengan Masa Angleb 2022 (%)</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {dalkot && dalkot.length > 0 && dalkot.map((Dalkot, index) => (
                  <tr key={index}>
                    <td>{Dalkot.Terminal}</td>
                    <td>{Dalkot.Akum_Pnp_DoD}</td>
                    <td>{Dalkot.Avg_PnpWeekday_YcY}</td>
                    <td>{Dalkot.Avg_PnpWeekend_YcY}</td>
                    <td>{Dalkot.Lonjakan}</td>
                    <td>{Dalkot.Comp_LY_Lonjakan} %</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary Kendaraan Tol</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Gerbang Tol</th>
                  <th>Akumulasi Volume Lalin Hingga Hari Ini</th>
                  <th>Volume Lalu Lintas Hari Ini</th>
                  <th>Rata-rata Volume Harian Weekdays pada Hari-hari Normal</th>
                  <th>Rata-rata Volume Harian Weekends pada Hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Perbandingan Lonjakan dengan Masa Angleb 2022 (%)</th>
                  <th>Vol Tahun Lalu</th>
                  <th>Masuk</th>
                  <th>Keluar</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {tol && tol.length > 0 && tol.map((Tol, index) => (
                  <tr key={index}>
                    <td>{Tol.GT}</td>
                    <td>{Tol.Akum_Vol_Lalin_DcD}</td>
                    <td>{Tol.Vol_Lalin_today}</td>
                    <td>{Tol.Avg_Vol_HarianNormal}</td>
                    <td>{Tol.Avg_Vol_HarianWeekend}</td>
                    <td>{Tol.Lonjakan_HariNormal} %</td>
                    <td>{Tol.Comp_LY_Lonjakan} %</td>
                    <td>{Tol.Vol_Lalin}</td>
                    <td>{Tol.Masuk}</td>
                    <td>{Tol.Keluar}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary Pelabuhan</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Pelabuhan</th>
                  <th>Akumulasi Penumpang Hingga Hari Ini</th>
                  <th>Jumlah Penumpang Hari Ini</th>
                  <th>Rata-rata Penumpang Weekdays pada Hari-hari Normal</th>
                  <th>Rata-rata Penumpang Weekends pada Hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Perbandingan Lonjakan dengan Masa Angleb 2022 (%)</th>
                  <th>Jumlah Penumpang Tahun Lalu</th>
                  <th>Penumpang Berangkat</th>
                  <th>Penumpang Tiba</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {pelabuhan && pelabuhan.length > 0 && pelabuhan.map((Spelabuhan, index) => (
                  <tr key={index}>
                    <td>{Spelabuhan.Pelabuhan}</td>
                    <td>{Spelabuhan.Akum_Penumpang_HHI}</td>
                    <td>{Spelabuhan.Tot_Pnp_Today}</td>
                    <td>{Spelabuhan.Avg_Pnp_HarianNormal}</td>
                    <td>{Spelabuhan.Avg_Pnp_HarianWeekend}</td>
                    <td>{Spelabuhan.Lonjakan_HariNormal} %</td>
                    <td>{Spelabuhan.Comp_LY_Lonjakan} %</td>
                    <td>{Spelabuhan.Tot_Pnp_LY}</td>
                    <td>{Spelabuhan.Pnp_Dep}</td>
                    <td>{Spelabuhan.Pnp_Arr}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary Penerbangan</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Penerbangan</th>
                  <th>Akumulasi Penumpang Hingga Hari Ini</th>
                  <th>Jumlah Penumpang Hari Ini</th>
                  <th>Rata-rata Penumpang pada Hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Penumpang Berangkat</th>
                  <th>Penumpang Tiba</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {penerbangan && penerbangan.length > 0 && penerbangan.map((Spenerbangan, index) => (
                  <tr key={index}>
                    <td>{Spenerbangan.Penerbangan}</td>
                    <td>{Spenerbangan.Akum_Penumpang_HHI}</td>
                    <td>{Spenerbangan.Tot_Pnp_Today}</td>
                    <td>{Spenerbangan.Avg_Pnp_HarianNormal}</td>
                    <td>{Spenerbangan.Comp_LY_Lonjakan} %</td>
                    <td>{Spenerbangan.Pnp_Dep}</td>
                    <td>{Spenerbangan.Pnp_Arr}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary RampCheck</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Terminal</th>
                  <th>Total Kendaraan Rampcheck</th>
                  <th>Laik Jalan</th>
                  <th>Tidak Laik Jalan</th>
                  <th>Laik Jalan dengan Catatan</th>
                  <th>% Total Keseluruhan</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {rcheck && rcheck.length > 0 && rcheck.map((Sramp, index) => (
                  <tr key={index}>
                    <td>{Sramp.Terminal}</td>
                    <td>{Sramp.Total_Knd}</td>
                    <td>{Sramp.Laik_Jalan}</td>
                    <td>{Sramp.No_Laik_Jalan}</td>
                    <td>{Sramp.LaikJalan_Catatan}</td>
                    <td>{Sramp.PersentaseKeseluruhan} %</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary KAJJ</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Angkutan</th>
                  <th>Akumulasi Penumpang Hari Ini</th>
                  <th>Jumlah Penumpang Hari Ini</th>
                  <th>Rata-rata Penumpang Weekdays pada hari-hari Normal</th>
                  <th>Rata-rata Penumpang Weekdays pada hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Perbandingan Lonjakan dengan Masa Angleb 2022 (%)</th>
                  <th>Jumlah Penumpang Tahun Lalu</th>
                  <th>Penumpang Berangkat</th>
                  <th>Penumpang Tiba</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {kajj && kajj.length > 0 && kajj.map((Skajj, index) => (
                  <tr key={index}>
                    <td>{Skajj.Stasiun}</td>
                    <td>{Skajj.Akum_Penumpang_HHI}</td>
                    <td>{Skajj.Tot_Pnp_Today}</td>
                    <td>{Skajj.Avg_Pnp_HarianNormal}</td>
                    <td>{Skajj.Avg_Pnp_HarianWeekend}</td>
                    <td>{Skajj.Comp_LonjakanHHI} %</td>
                    <td>{Skajj.Comp_LY_Lonjakan} %</td>
                    <td>{Skajj.Tot_Pnp_LY}</td>
                    <td>{Skajj.Pnp_Dep}</td>
                    <td>{Skajj.Pnp_Arr}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Summary AUM</h3>
          </div>
          <div className="card-body">
            <table
              id="table_id"
              className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
            >
              <thead>
                <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                  <th>Angkutan</th>
                  <th>Akumulasi Penumpang Hari Ini</th>
                  <th>Jumlah Penumpang Hari Ini</th>
                  <th>Rata-rata Penumpang Weekdays pada hari-hari Normal</th>
                  <th>Rata-rata Penumpang Weekdays pada hari-hari Normal</th>
                  <th>Perbandingan Lonjakan Pada hari-hari Normal (%)</th>
                  <th>Perbandingan Lonjakan dengan Masa Angleb 2022 (%)</th>
                  <th>Jumlah Penumpang Tahun Lalu</th>
                  <th>Penumpang Berangkat</th>
                  <th>Penumpang Tiba</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "13px" }}>
                {saum && saum.length > 0 && saum.map((Saum, index) => (
                  <tr key={index}>
                    <td>{Saum.Angkutan}</td>
                    <td>{Saum.Akum_Penumpang_HHI}</td>
                    <td>{Saum.Tot_Pnp_Today}</td>
                    <td>{Saum.Avg_Pnp_HarianNormal}</td>
                    <td>{Saum.Avg_Pnp_HarianWeekend}</td>
                    <td>{Saum.Comp_LonjakanHHI} %</td>
                    <td>{Saum.Comp_LY_Lonjakan} %</td>
                    <td>{Saum.Tot_Pnp_LY}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailAngleb2023;
