import { SmallBox } from '@app/components';
import React, { Component, useState, useEffect } from 'react';
import { ContentHeader } from '@components';
import { Link } from 'react-router-dom';
import Blank from './Blank';
import axios from "axios";

function Dashboard(props: any) {
  const [uppd, setUppd] = useState([]);

  const DataPenumpang = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/caeb3756-653b-444a-8d74-7e5b813df4da/page/daSTD"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };
  const DataStatis = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/4d9e7ed1-842b-4779-8261-654e2eeff4ac/page/1M"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  return (
    <div>
      <ContentHeader title="Portal Data Dishub DKI Jakarta" />
      <div className="row">
        {/* <a href="/fileUpload">
          <button
            type="button"
            className="btn btn-block bg-gradient-info btn-md"
          >
            Upload File
          </button>
        </a> */}
      </div>

      {/* First Row: MRT, LRT, TransJakarta */}
      {/* <div className="row">
        <div className="col-lg-4">
          <div className="small-box" style={{ backgroundColor: "#54B435", color: "#ffffff" }}>
            <div className="inner">
              <p style={{ marginTop: "30px" }}></p>
              <p>MRT</p>
            </div>
            <div className="icon">
              <i className="far fa-subway"></i>
            </div>
            <a href="/mrt" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="small-box" style={{ backgroundColor: "#C51605", color: "#ffffff" }}>
            <div className="inner">
              <p style={{ marginTop: "30px" }}></p>
              <p>LRT</p>
            </div>
            <div className="icon">
              <i className="far fa-train"></i>
            </div>
            <a href="/lrt" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="small-box" style={{ backgroundColor: "#1D5D9B", color: "#ffffff" }}>
            <div className="inner">
              <p style={{ marginTop: "30px" }}></p>
              <p>TransJakarta</p>
            </div>
            <div className="icon">
              <i className="far fa-bus"></i>
            </div>
            <a href="/transjakarta" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
      </div> */}

      {/* Second Row: Angkutan Perairan, Geomap */}
      <div className="row">
        <div className="col-lg-6">
          <div className="small-box" style={{ backgroundColor: "#61677A", color: "#ffffff" }}>
            <div className="inner">
              <p style={{ marginTop: "30px" }}></p>
              <p>Data Agregat Penumpang</p>
            </div>
            <div className="icon">
              <i className="far fa-bus"></i>
            </div>
            <a href="/agregatPenumpang" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="small-box" style={{ backgroundColor: "#4B2E83", color: "#ffffff" }}>
            <div className="inner">
              <p style={{ marginTop: "30px" }}></p>
              <p>Geomap <span>(Tracking Petugas Lapangan)</span></p>
            </div>
            <div className="icon">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <a
              href="https://dsbgeo.jaktraffic.my.id"
              className="small-box-footer"
              target="_blank"
              rel="noopener noreferrer"
            >
              More info <i className="fas fa-arrow-circle-right"></i>
            </a>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col">
          <div className="card card-outline card-primary mt-2">
            <div className="card-header">
              <h3 className="card-title"></h3>
            </div>
            <div className="card-body">
              <DataStatis />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
