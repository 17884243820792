import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MenuItem } from '@components';
import { PfImage } from '@profabric/react-components';
import styled from 'styled-components';
import { SidebarSearch } from '@app/components/sidebar-search/SidebarSearch';
import i18n from '@app/utils/i18n';

export interface IMenuItem {
  name: string;
  icon?: string;
  path?: string;
  children?: Array<IMenuItem>;
}

export const MENU: IMenuItem[] = [
  // {
  //   name: i18n.t('Main Dashboard'),
  //   icon: 'far fa-book nav-icon',
  //   path: '/'
  // },
  // {
  //   name: i18n.t('BAJ'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('MRT'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/mrt'
  //     },
  //     {
  //       name: i18n.t('LRT'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/lrt'
  //     },
  //     {
  //       name: i18n.t('TRANJAKARTA'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/transjakarta'
  //     },
  //   ]
  // },
  // {
  //   name: i18n.t('BLL'),
  //   icon: 'fas fa-folder nav-icon',
  //   path: '/DashboardBLL'
  // },
  // {
  //   name: i18n.t('BKA'),
  //   icon: 'fas fa-folder nav-icon',
  //   path: '/DashboardBKA'
  // },
  // {
  //   name: i18n.t('BPP'),
  //   icon: 'fas fa-folder nav-icon',
  //   path: '/DashboardBPP'
  // },
  // {
  //   name: i18n.t('Bid-DALOPS'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('SIMBADA'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/sub-menu-1'
  //     },
  //   ]
  // },
  // {
  //   name: i18n.t('Sudinhub DKI Jakarta'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('Sudin Jakarta Barat'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/DashboardSudinJB'
  //     },
  //     {
  //       name: i18n.t('Sudin Jakarta Pusat'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/DashboardSudinJP'
  //     },
  //     {
  //       name: i18n.t('Sudin Jakarta Selatan'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/DashboardSudinJS'
  //     },
  //     {
  //       name: i18n.t('Sudin Jakarta Utara'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/DashboardSudinJU'
  //     },
  //   ]
  // },
  // {
  //   name: i18n.t('UPAP'),
  //   icon: 'fas fa-folder nav-icon',
  //   path: '/DashboardUPAP'
  // },
  // {
  //   name: i18n.t('Angkutan Sekolah'),
  //   icon: 'far fa-book nav-icon',
  //   path: '/DashboardUPAS'
  // },
  {
    name: i18n.t('Data Penumpang'),
    icon: 'fas fa-folder nav-icon',
    children: [
      {
        name: i18n.t('Data Agregat Penumpang'),
        icon: 'far fa-circle nav-icon',
        path: '/agregatPenumpang'
      },
      {
        name: i18n.t('Transjakarta'),
        icon: 'far fa-circle nav-icon',
        path: '/transjakarta'
      },
      // {
      //   name: i18n.t('MRT'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/mrt'
      // },
      // {
      //   name: i18n.t('LRT'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/lrt'
      // },
      // {
      //   name: i18n.t('Angkutan Perairan'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/penumpangPelabuhan'
      // },
      // {
      //   name: i18n.t('Angkutan Sekolah'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/DashboardUPAS'
      // },
    ]
  },
  {
    name: i18n.t('Data Terminal'),
    icon: 'fas fa-folder nav-icon',
    children: [
      {
        name: i18n.t('Peta Lokasi Terminal'),
        icon: 'far fa-circle nav-icon',
        path: '/PetaLokasiTerminal'
      },
      {
        name: i18n.t('Penumpang Terminal'),
        icon: 'far fa-circle nav-icon',
        path: '/penumpangTerminal'
      },

    ]
  },
  {
    name: i18n.t('Data Lalu Lintas'),
    icon: 'fas fa-folder nav-icon',
    children: [
      {
        name: i18n.t('Vol Lalin Perbatasan'),
        icon: 'far fa-circle nav-icon',
        path: '/DashboardSPLL'
      },
      {
        name: i18n.t('Jumlah Kendaraan Bermotor'),
        icon: 'far fa-circle nav-icon',
        path: '/jumlahKendaraan'
      },
      // {
      //   name: i18n.t('Peta Lokasi APILL'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/PetaAPILL'
      // },
      // {
      //   name: i18n.t('Peta Sensor Traffic Count'),
      //   icon: 'far fa-circle nav-icon',
      //   path: '/sensorTraffic'
      // },
    ]
  },
  // {
  //   name: i18n.t('Data Penindakan'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('Data Tindak Dallops'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/tindakDallops'
  //     },
  //     {
  //       name: i18n.t('Data Tindak Jakut'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/tindakJakut'
  //     },
  //     {
  //       name: i18n.t('Data Tindak Jakbar'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/tindakJakbar'
  //     },
  //     {
  //       name: i18n.t('Data Tindak Jakpus'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/tindakJakpus'
  //     },
  //     {
  //       name: i18n.t('Data Tindak Jaksel'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/tindakJaksel'
  //     },
  //     {
  //       name: i18n.t('Data Tindak Jaktim'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/tindakJaktim'
  //     },
  //     {
  //       name: i18n.t('Summary Data Penindakan'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/tindakSummary'
  //     },

  //   ]
  // },
  {
    name: i18n.t('Data Pengujian'),
    icon: 'fas fa-folder nav-icon',
    children: [
      {
        name: i18n.t('Pengujian Kendaraan Bermotor'),
        icon: 'far fa-circle nav-icon',
        path: '/PKB_Angke'
      },
    ]
  },
  // {
  //   name: i18n.t('Data HBKB'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('Dashboard HBKB'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/DashboardHBKB'
  //     },
  //   ]
  // },

  {
    name: i18n.t('Data Bongkar Muat'),
    icon: 'fas fa-folder nav-icon',
    children: [
      {
        name: i18n.t('Dashboard Bongkar Muat'),
        icon: 'far fa-circle nav-icon',
        path: '/DashboardBongkarMuat'
      },
    ]
  },

  // {
  //   name: i18n.t('Jaklingko Indonesia'),
  //   icon: 'fas fa-book nav-icon',
  //   path: '/jaklingko'
  // },

  // {
  //   name: i18n.t('Data Pegawai'),
  //   icon: 'fas fa-book nav-icon',
  //   path: '/dataPegawai'
  // },
  {
    name: i18n.t('Angkutan Lebaran'),
    icon: 'far fa-book nav-icon',
    path: '/dataproduksiTerminal'
  },
  {
    name: i18n.t('Angkutan Nataru'),
    icon: 'far fa-book nav-icon',
    path: '/DashboardNataru'
  },

  // {
  //   name: i18n.t('GeoLocation'),
  //   icon: 'far fa-book nav-icon',
  //   path: '/geolocation'
  // },

  // {
  //   name: i18n.t('UPPKB'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('PKB Angke'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/PKB_Angke'
  //     },
  //     {
  //       name: i18n.t('PKB Cilincing'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/PKB_Cilincing'
  //     },
  //     {
  //       name: i18n.t('PKB Pulo Gadung'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/PKB_Pulogadung'
  //     },
  //     {
  //       name: i18n.t('PKB Ujung Menteng'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/PKB_Ujungmenteng'
  //     },
  //   ]
  // },
  // {
  //   name: i18n.t('UPPARKIR'),
  //   icon: 'fas fa-folder nav-icon',
  //   path: '/DashboardUPparkir'
  // },
  // {
  //   name: i18n.t('UPPD'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('Dashboard UPPD'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/DashboardUPPD'
  //     }
  //   ]
  // },
  // {
  //   name: i18n.t('UPSJBE'),
  //   icon: 'fas fa-folder nav-icon',
  //   path: '/DashboardUPSJBE'
  // },

  // {
  //   name: i18n.t('UPTAJ'),
  //   icon: 'fas fa-folder nav-icon',
  //   children: [
  //     {
  //       name: i18n.t('E-Kios UPTAJ'),
  //       icon: 'far fa-circle nav-icon',
  //       path: '/DashboardUPTAJ'
  //     }
  //   ]
  // },
];

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const StyledBrandImage = styled(PfImage)`
  float: left;
  line-height: 0.8;
  margin: -1px 8px 0 6px;
  opacity: 0.8;
  --pf-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23) !important;
`;

const MenuSidebar = () => {
  const user = useSelector((state: any) => state.auth.currentUser);
  const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link">
        <StyledBrandImage
          src="/img/dishub.png"
          alt="dishub Logo"
          width={33}
          height={33}
          rounded
        />
        <span className="brand-text font-weight-bold">Portal Data Dishub</span>
      </Link>
      <div className="sidebar">
        {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex"> */}
        {/* <div className="image">
            <StyledUserImage
              src={user.picture}
              fallbackSrc="/img/default-profile.png"
              alt="User"
              width={34}
              height={34}
              rounded
            />
          </div> */}
        {/* <div className="info">
            <Link to="/profile" className="d-block">
              {user.email}
            </Link>
          </div> */}
        {/* </div> */}

        {/* <div className="form-inline">
          <SidebarSearch />
        </div> */}

        <nav className="mt-2" style={{ overflowY: 'hidden' }}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${menuItemFlat ? ' nav-flat' : ''
              }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {MENU.map((menuItem: IMenuItem) => (
              <MenuItem
                key={menuItem.name + menuItem.path}
                menuItem={menuItem}
              />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
