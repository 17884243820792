import { SmallBox } from "@app/components";
import { ContentHeader } from "@components";
import { useEffect, useState } from "react";
import axios from "axios";


function GeoLocation(props: any) {
    const [userdata, setUserdata] = useState([]);
    const getUser = async () => {
        let response
        try {
            response = await axios.get(
                `http://192.168.60.56:4480/geoapi/user/viewu`
            );
            //console.log(response);
            setUserdata(response.data);
        } catch (error: any) {
            //console.log(error.message);
            //console.log(response.data.message)
            // await Swal.fire({
            //     title: 'Gagal',
            //     text: error.response.data.message,
            //     icon: 'error'
            // });
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div>

            <ContentHeader title="Data Geolocation Petugas Lapangan" />
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Data Seluruh Petugas Lapangan</h3>

                </div>

                <div className="card-body p-0">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>UserID</th>
                                <th>Nama</th>
                                <th>Email</th>
                                <th>Wilayah</th>
                                <th>Satuan</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userdata.map((user, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{user.userID}</td>
                                    <td>{user.Nama}</td>
                                    <td>{user.Email}</td>
                                    <td>{user.Wilayah}</td>
                                    <td>{user.Satuan}</td>
                                    <td>{user.Role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};

export default GeoLocation;


