import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
  user_id: number | null;
  role: string | null;
  nama: string | null;
  email: string | null;
  unit: string | null;
  position: string | null;
  currentUser: any;
  apiUrlProd: string | null;
  apiUrlDev: string | null;
}

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  token: localStorage.getItem('token'),
  user_id: localStorage.getItem('user_id') ? Number(localStorage.getItem('user_id')) : null,
  role: localStorage.getItem('role'),
  nama: localStorage.getItem('nama'),
  unit: localStorage.getItem('unit'),
  position: localStorage.getItem('position'),
  email: localStorage.getItem('email'),
  currentUser: {
    email: localStorage.getItem('email'),
    nama: localStorage.getItem('nama'),
    role: localStorage.getItem('role'),
    unit: localStorage.getItem('unit'),
    position: localStorage.getItem('position'),
    user_id: localStorage.getItem('user_id') ? Number(localStorage.getItem('user_id')) : null,
    picture: null
  },
  apiUrlProd: 'https://portaldata.jaktraffic.my.id',
  apiUrlDev: 'http://192.168.60.100:4123'
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, action) => {
      const { token, user_id, role, nama, email, unit, position } = action.payload;
      localStorage.setItem('token', token);
      localStorage.setItem('user_id', String(user_id));
      localStorage.setItem('role', role);
      localStorage.setItem('nama', nama);
      localStorage.setItem('email', email);
      localStorage.setItem('unit', unit);
      localStorage.setItem('position', position);

      state.isLoggedIn = true;
      state.token = token;
      state.user_id = user_id;
      state.role = role;
      state.nama = nama;
      state.email = email;
      state.unit = unit;
      state.position = position;
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('role');
      localStorage.removeItem('nama');
      localStorage.removeItem('email');
      localStorage.removeItem('unit');
      localStorage.removeItem('position');
      state.currentUser = {};
      state.isLoggedIn = false;
      state.token = null;
    },
    loadUser: (state, { payload }) => {
      state.currentUser = payload;
    }
  }
});

export const { loginUser, logoutUser, loadUser } = authSlice.actions;

export default authSlice.reducer;
