import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";
import { token, tokenAuth } from '@app/store/reducers/tokenAuth';


function PetaAPILL(props: any) {
    // const [token, setToken] = useState('');
    // const [uppd, setUppd] = useState([]);

    const DataApil = () => {
        return (
            <div>
                <iframe
                    width="100%"
                    height="600"
                    src="https://lookerstudio.google.com/embed/reporting/ea24ba26-daf3-437a-814b-586d1b83f716/page/5f9UD"
                    frameBorder="0"
                    style={{ border: '0' }}
                    allowFullScreen
                />
            </div>
        );
    };

    return (
        <div>
            <ContentHeader title="Peta Lokasi Alat Pemberi Isyarat Lalu Lintas" />
            <div className="card card-outline card-primary">
                <div className="card-header">
                    <h3 className="card-title"></h3>
                </div>
                <div className="card-body">
                    <DataApil />
                </div>
            </div>
        </div>
    );
}

export default PetaAPILL;
