import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { loginUser } from '@store/reducers/auth';
import { setWindowClass } from '@app/utils/helpers';
import { PfButton, PfCheckbox } from '@profabric/react-components';
import axios, { Axios } from 'axios';

import * as Yup from 'yup';

import { Form, InputGroup } from 'react-bootstrap';
import { token, tokenAuth } from '@app/store/reducers/tokenAuth';

const Login = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const [isFacebookAuthLoading, setFacebookAuthLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [error, setError] = useState('');
  const baseUrlDev = useSelector((state: any) => state.auth.apiUrlDev);
  const baseUrl = useSelector((state: any) => state.auth.apiUrlProd);
  const API_URL = `${baseUrl}/APIS/User/login`;
  const getTkn = `${baseUrl}/APIS/User/hitTk3N`;


  // const [token, setToken] = useState('');
  useEffect(() => {
    // Fetch API untuk mendapatkan token
    axios.get(getTkn)
      .then((response) => {
        tokenAuth(response.data.token);
        // console.log(response.data.token)
      });
  }, []);

  const handleLogin = (email: string, password: string) => {
    setAuthLoading(true);
    axios.post(API_URL, {
      Email: email,
      Password: password
    }, {
      headers: {
        'x-access-token': token,
      }
    }).then((response) => {
      tokenAuth(response.data.token);

      localStorage.setItem('userData', JSON.stringify(response.data));
      // console.log('Login berhasil:', response.data);

      const userData = {
        Nama: response.data.nama,
        Role: response.data.role,
        Unit: response.data.unit,
        Position: response.data.position
      };
      toast.success('Login succeeded!');
      setAuthLoading(false);
      dispatch(loginUser(response.data.token));
      navigate('/');
    }).catch((err) => {
      console.error(err);

      if (err.response && err.response.data && err.response.data.message) {
        const errorMessage = err.response.data.message;
        setError(errorMessage);
        toast.error(errorMessage);
      } else {
        setError('Invalid email or password');
        toast.error('Invalid email or password');
      }

      setAuthLoading(false);
    });
  };



  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      Email: '',
      Password: ''
    },
    validationSchema: Yup.object({
      Email: Yup.string().email('Invalid email address').required('Required'),
      Password: Yup.string()
        .min(5, 'Must be 5 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: (values) => {
      handleLogin(values.Email, values.Password);
    }
  });

  setWindowClass('hold-transition login-page');

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          < img src="img/dishub.png" alt="dishub_logo">
          </img><br></br>
          <Link to="/" className="mb-1">
            <text><h2><b>PORTAL DATA</b></h2></text>
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg"> {t<string>('login.label.signIn')}</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-0">
              <InputGroup className="mb-3">
                <Form.Control
                  id="Email"
                  name="Email"
                  type="Email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.Email}
                  isValid={touched.Email && !errors.Email}
                  isInvalid={touched.Email && !!errors.Email}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
                {touched.Email && errors.Email ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.Email}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-envelope" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="Password"
                  name="Password"
                  type="Password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.Password}
                  isValid={touched.Password && !errors.Password}
                  isInvalid={touched.Password && !!errors.Password}
                  onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
                {touched.Password && errors.Password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.Password}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <i className="fas fa-lock" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="flex-column justify-content-center align-items-center ">

              <PfButton
                block
                type="submit"
                loading={isAuthLoading}
                disabled={isFacebookAuthLoading || isGoogleAuthLoading}
              >
                {t<string>('Login')}

              </PfButton>
            </div>
          </form>
          <p className="mb-1 mt-2">
            <Link to="/forgot-password">
              {t<string>('login.label.forgotPass')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
function setError(arg0: string) {
  throw new Error('Function not implemented.');
}

