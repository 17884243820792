let token: string | null = null;

function tokenAuth(fetchedToken: string) {
    token = fetchedToken;
}
//console.log(token);
export { token, tokenAuth };

// import { configureStore } from '@reduxjs/toolkit';

// interface TokenState {
//     token: string | null;
// }

// enum ActionType {
//     SET_TOKEN = 'SET_TOKEN',
// }

// const setToken = (token: string | null) => ({
//     type: ActionType.SET_TOKEN,
//     payload: { token }
// });

// const initialState: TokenState = {
//     token: null,
// }

// const tokenReducer = (state: TokenState = initialState, action: any) => {
//     switch (action.type) {
//         case ActionType.SET_TOKEN:
//             return {
//                 ...state,
//                 token: action.payload.token,
//             };
//         default:
//             return state;
//     }
// };

// const store = configureStore({
//     reducer: tokenReducer
// });

// store.subscribe(() => {
//     const { token } = store.getState();
//     if (token) {
//         localStorage.setItem('token', token);
//     } else {
//         localStorage.removeItem('token');
//     }
// });

// const storedToken = localStorage.getItem('token');
// if (storedToken) {
//     store.dispatch(setToken(storedToken));
// }

// export { store, setToken };