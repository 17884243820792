import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";
import { token, tokenAuth } from '@app/store/reducers/tokenAuth';



function Lrt(props: any) {
  // const [token, setToken] = useState('');
  const [uppd, setUppd] = useState([]);
  const DataLRT = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/99cefb80-f851-4943-bdeb-07a6bd4da8c2/page/OWHRD"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  return (
    <div>
      <ContentHeader title="Data Penumpang LRT" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title"></h3>
        </div>
        <div className="card-body">
          <DataLRT />
        </div>
      </div>
    </div>
  );
}

export default Lrt;
