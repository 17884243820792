import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";
import { token, tokenAuth } from '@app/store/reducers/tokenAuth';

const API_URL = 'https://portaldata.jaktraffic.my.id/APIS/UPPD/all/1000';

function DashboardUPPD(props: any) {
  // const [token, setToken] = useState('');
  const [uppd, setUppd] = useState([]);

  const getUppd = async () => {
    try {
      const response = await axios.get(API_URL);
      setUppd(response.data.message);
      //console.log(response);
    } catch (error) {
      console.error(API_URL);
    }
  };

  useEffect(() => {
    getUppd();
  }, []);

  return (
    <div>
      <ContentHeader title="Dashboard UPPD" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title"></h3>
        </div>
        <div className="card-body">
          <table
            id="table_id"
            className="table table-head-fixed text-nowrap table-bordered table-responsive table-bordered dataTable dtr-inline"
          >
            <thead>
              <tr>
                <th>Waktu</th>
                <th>Pelabuhan</th>
                <th>Rute</th>
                <th>Nama Kapal</th>
                <th>Nahkoda</th>
                <th>Waktu Keberangkatan</th>
                <th>Waktu Kedatangan</th>
                <th>Penumpang Naik</th>
                <th>Penumpang Turun</th>
                <th>Kapasitas</th>
                <th>Okupansi</th>
              </tr>
            </thead>
            <tbody>
              {uppd.map((uppa, index) => {
                return (
                  <tr key={index}>
                    <td>-</td>
                    <td>{uppa.nama_pelabuhan}</td>
                    <td>{uppa.rute}</td>
                    <td>{uppa.nama_kapal}</td>
                    <td>{uppa.nakhoda}</td>
                    <td>{uppa.check_in_date}</td>
                    <td>{uppa.check_out_date}</td>
                    <td>{uppa.penumpang_naik}</td>
                    <td>{uppa.penumpang_turun}</td>
                    <td>{uppa.kapasitas}</td>
                    <td>{uppa.okupansi}%</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="card-footer clearfix">
          <ul className="pagination pagination-sm m-0 float-right">
            <li className="page-item">
              <a className="page-link" href="#">
                «
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                »
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DashboardUPPD;
