import {SmallBox} from '@app/components';
import React from 'react';
import {ContentHeader} from '@components';
import { Link } from 'react-router-dom';



const UPTAJ_documentKadaluarsa = () => {
  return (
    <div>
      <ContentHeader  title="Tambah Permohonan Kadaluarsa"  />
      <div className="row">
        <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
          {/* <h3 className="card-title"></h3> */}
          </div>
          <form id="quickForm" >
            <div className="card-body">
            <div className="form-group">
              {/* <label class="custom-file-label" for="customFile">Choose file</label> */}
            {/* <input type="file" class="custom-file-input" id="customFile"> */}
            <label>
              KTP
              <span className="text-danger">*</span>
            </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th87620.pdf"/>
              {/* <button type="custom-file" className="btn btn-primary">Upload File</button> */}
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th87620.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                KK
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th27138.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th27138.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Izin Usaha
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th88277.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th88277.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Bukti Pembayaran Surat Setoran Retribusi Daerah
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th13854.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th13854.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                NPWP
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th66296.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th66296.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Buku Tabungan Bank DKI
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th72727.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th72727.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Bukti Pelunasan Surat Ketetapan Retribusi Daerah
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th78712.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th78712.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Surat Pernyataan Kesanggupan Dengan Materai 10.000
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th30921.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th30921.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Pas Foto 4R
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th31412.jpeg"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th31412.jpeg" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Denah Lokasi Kios
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th46435.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th46435.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>
            <div className="form-group">
              <label>
                Surat Pengantar Kepala Satuan Pelaksana Terminal
                <span className="text-danger">*</span>
              </label>
              <br></br>
              <input type="hidden" name="requirements_template_id[]" className="form-control" id="formInputKTP" placeholder=""/>
              <input className="form-control" type="file" name="requirement[]" accept="image/png, image/gif, image/jpeg, .pdf"/>
              <input type="hidden" name="requirement_temp[]" value="2023FebWed130th47402.pdf"/>
            </div>
            <small>(maksimal 2 mb) </small>
             <small>
              <a href="https://servis.sutawangi.xyz/documents/2023FebWed130th47402.pdf" target="_blank">
                <span className="label label-primary">lihat</span>
              </a>
            </small>

            {/* <div className="form-group">
            <label>Disabled</label>
              <select class="form-control select2 select2-hidden-accessible" disabled=""  data-select2-id="4" tabindex="-1" aria-hidden="true">
                <option>Perpanjangan</option>
              </select>
            </div> */}
            </div>
            {/* <div className="card-footer">
              <div className="row">  */}
                {/* <div className="col-md-11">
                  <a href="/permPendaftaranUPTAJ">
                    <button type="button" className="btn btn-danger">Batal</button>
                  </a>
                </div> */}
                {/* <div className="col-md-1 d-flex">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div> */}
              {/* </div>
            </div> */}
            <div className="btn-row mb-4 ml-4 btn-default btn-flat float-left">
          <div className="buttonBack">
            <a href="/DashboardUPTAJ">
              <button
                type="button"
                className="btn btn-danger btn-secondary btn-sm">
                <span className="glyphicon glyphicon-arrow-left"></span>
                Kembali
              </button>
            </a>
          </div>
        </div>
        <div className="btn-row mb-4 mr-4 btn-default btn-flat float-right">
          <div className="buttonBack">
            <a href="/DashboardUPTAJ">
              <button
                type="submit"
                name="simpan"
                className="btn btn-primary btn-sm pull-right">
                <span className="glyphicon glyphicon-save"></span>
                simpan
              </button>
            </a>
          </div>
        </div>
          </form>
      </div>
        </div>
      </div>
    </div>
    
  );
};

export default UPTAJ_documentKadaluarsa;
