import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";
import { token, tokenAuth } from '@app/store/reducers/tokenAuth';


function PetaLokasiTerminal(props: any) {

    const PetaLokasi = () => {
        return (
            <div>
                <iframe
                    width="100%"
                    height="600"
                    src="https://lookerstudio.google.com/embed/reporting/7f0ae90a-ebde-4499-b8a8-50ef5e88820a/page/rRdTD"
                    frameBorder="0"
                    style={{ border: '0' }}
                    allowFullScreen
                />
            </div>
        );
    };

    // const getUppd = async()=>{
    //   try {
    //     const response = await axios.get(API_URL);
    //     setUppd(response.data.message);
    //     console.log(response);
    //   } catch (error) {
    //     console.error(API_URL);
    //   }
    // };

    //   useEffect(() => {
    //       getUppd();
    //   }, []);

    return (
        <div>
            <ContentHeader title="Peta Lokasi Terminal" />
            <div className="card card-outline card-primary">
                <div className="card-header">
                    <h3 className="card-title"></h3>
                </div>
                <div className="card-body">
                    <PetaLokasi />
                </div>
            </div>
        </div>
    );
}

export default PetaLokasiTerminal;
