import { SmallBox } from '@app/components';
import React, { Component, useState, useEffect } from 'react';
import { ContentHeader } from '@components';
import { Link } from 'react-router-dom';
import Blank from './Blank';
import axios from "axios";

function DashboardBongkarMuat(props: any) {
  const [uppd, setUppd] = useState([]);

  const DataBongkar = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/94b88bfa-24a6-48ac-ac63-c50f58cd7a49/page/IXd9D"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  return (
    <div>
      <ContentHeader title="Portal Data Dishub DKI Jakarta" />
      <div className="row">
        <div className="col">
          <div className="card card-outline card-primary mt-2">
            <div className="card-header">
              <h3 className="card-title"></h3>
            </div>
            <div className="card-body">
              <DataBongkar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBongkarMuat;
