import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function DataTindakJaksel(props: any) {
  // const [token, setToken] = useState('');
  const TindakJaksel = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://192.168.60.21:81/#//pdsshare/dashboard/view/shared?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFeHBpcmVkIjoyMDM5NTg2ODY3LCJJZGVudGl0eSI6MzgwMDcsIlRva2VuVHlwZSI6MH0.gmhyZWcgt3kf6OYdZ4k0htdzazvhP7iGllo-FKCiEeg&theme=light"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    )

  };

  return (
    <div>
      <ContentHeader title="Data Penindakan Jakarta Selatan" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Data Penindakan Jakarta Selatan</h3>
        </div>
        <div className="card-body">
          <TindakJaksel />
        </div>
      </div>
    </div>
  );
}

export default DataTindakJaksel;
