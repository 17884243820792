import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function JumlahKendaraan(props: any) {
  // const [token, setToken] = useState('');
  const JmlKendaraan = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/f2ae83a3-0b3f-4007-a615-a4cbafcb4625/page/1M"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    )

  };

  return (
    <div>
      <ContentHeader title="Data Jumlah Kendaraan Bermotor" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Data Jumlah Kendaraan Bermotor</h3>
        </div>
        <div className="card-body">
          <JmlKendaraan />
        </div>
      </div>
    </div>
  );
}

export default JumlahKendaraan;
