import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function PKB_Angke(props: any) {
  // const [token, setToken] = useState('');
  
  const DataPKB = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="538"
          src="https://192.168.60.21:81/#//pdsshare/dashboard/view/shared?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFeHBpcmVkIjoxOTk5ODI0NzI5LCJJZGVudGl0eSI6NjAwMSwiVG9rZW5UeXBlIjowfQ.yLaoD-pZUiEo6_aHLjjX03LdA6fRbojANp4sfMaqjsE&theme=light" 
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  return (
    <div>
      <ContentHeader title="Data Pengujian Kendaraan Bermotor" />
      <div className="row">
      <div className="card card-outline card-primary" style={{ width:'100%',height:'100vh'}}>
        <div className="card-header">
          <h3 className="card-title">Data PKB</h3>
        </div>
        <div className="card-body" >
        <DataPKB/>
        </div>
      </div>
      </div>
    </div>
  );
}

export default PKB_Angke;
