import {SmallBox} from '@app/components';
import React from 'react';
import {ContentHeader} from '@components';
import { Link } from 'react-router-dom';



const UPTAJ_formPendaftaran = () => {
  return (
    <div>
      <ContentHeader  title="Tambah Permohonan Pendaftaran"  />
      <div className="row">
        <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
          {/* <h3 className="card-title"></h3> */}
          </div>
          <form id="quickForm" noValidate="novalidate">
            <div className="card-body">
            <div className="form-group">
              <label for="formInputKTP">No. KTP</label>
              <input type="ktp" name="ktp" className="form-control" id="formInputKTP" placeholder=""/>
            </div>
            <div className="form-group">
              <label for="formInputNama">Nama Pemohon</label>
              <input type="nama" name="nama" className="form-control" id="formInputNama" placeholder=""/>
            </div>
            <div className="form-group">
              <label for="formInputHP">No. Handphone</label>
              <input type="hp" name="hp" className="form-control" id="formInputHP" placeholder=""/>
            </div>
            <div className="form-group">
            <label>Disabled</label>
              <select class="form-control select2 select2-hidden-accessible" disabled=""  data-select2-id="4" tabindex="-1" aria-hidden="true">
                <option>Pendaftaran</option>
              </select>
            </div>
            </div>
            <div className="card-footer">
              <div className="row"> 
                <div className="col-md-11">
                  <a href="/permPendaftaranUPTAJ">
                    <button type="button" className="btn btn-danger">Batal</button>
                  </a>
                </div>
                <div className="col-md-1 d-flex">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UPTAJ_formPendaftaran;
