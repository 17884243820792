import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function dataPegawai(props: any) {
  // const [token, setToken] = useState('');
  const Pegawai = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://192.168.60.21:81/#//pdsshare/dashboard/view/shared?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJFeHBpcmVkIjoyMDAwMzQ1NDk2LCJJZGVudGl0eSI6NjI0MywiVG9rZW5UeXBlIjowfQ.y_5xIQhvGkcd0UKHQT4mj5h8gE--1oYR-tb19rv-53I&theme=light"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    )

  };

  return (
    <div>
      <ContentHeader title="Data Pegawai Dinas Perhubungan DKI Jakarta" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title">Data Pegawai Dinas Perhubungan DKI Jakarta</h3>
        </div>
        <div className="card-body">
          <Pegawai />
        </div>
      </div>
    </div>
  );
}

export default dataPegawai;
