import { SmallBox } from "@app/components";
import React, { useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import axios, { AxiosResponse, AxiosError } from 'axios';
import { getIn } from "formik";
import { useDispatch, useSelector } from 'react-redux';


function DataproduksiTerminal() {
  const [token, setToken] = useState('');
  const [sterminal, setSterminal] = useState([]);
  const [sarteri, setSarteri] = useState([]);
  const [dalkot, setDalkot] = useState([]);
  const [tol, setTol] = useState([]);
  const [pelabuhan, setPelabuhan] = useState([]);
  const [penerbangan, setPenerbangan] = useState([]);
  const [rcheck, setRcheck] = useState([]);
  const [kajj, setKajj] = useState([]);
  const [saum, setAum] = useState([]);
  const baseUrlDev = useSelector((state: any) => state.auth.apiUrlDev);
  const baseUrl = useSelector((state: any) => state.auth.apiUrlProd);
  const getTkn = `${baseUrl}/APIS/User/hitTk3N`;

  const Angleb2022 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="538"
          src="https://lookerstudio.google.com/embed/reporting/3bdd4a22-7dd3-42df-8f80-faa4d2ee39c8/page/XYjqC"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  const Angleb2023 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="538"
          src="https://lookerstudio.google.com/embed/reporting/aa07d042-03de-4963-af9b-98040f583ca8/page/XYjqC"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  const Angleb2024 = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="538"
          src="https://lookerstudio.google.com/embed/reporting/82dd2c49-fe7b-411e-a71b-16b7e65d80f3/page/p_bf557rteuc"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };

  // const getSummaryTerminal = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setSterminal(responseSummary.data.SummaryTerminal);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getSummaryKendaraanArteri = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setSarteri(responseSummary.data.SummaryKendaraanArteri);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getSummaryKendaraanDalkot = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setDalkot(responseSummary.data.SummaryKendaraanDalkot);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // const getSummaryKendaraanTol = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setTol(responseSummary.data.SummaryKendaraanTol);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getSummaryPelabuhan = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setPelabuhan(responseSummary.data.SummaryPelabuhan);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getSummaryPenerbangan = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setPenerbangan(responseSummary.data.SummaryPenerbangan);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getSummaryRampCheck = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setRcheck(responseSummary.data.SummaryRampCheck);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getSummaryKAJJ = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setKajj(responseSummary.data.SummaryKAJJ);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const getSummaryAUM = async()=>{
  //   try {
  //     const responseSummary = await axios.get(API_URL, {

  //       headers: {
  //         'pdtn-token-angleb23': token,
  //       }
  //     });
  //     console.log(responseSummary);
  //     setAum(responseSummary.data.SummaryAUM);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  useEffect(() => {
    const getToken = () => {
      // Fetch API untuk mendapatkan token
      axios.get(getTkn)
        .then((response) => {
          setToken(response.data.token);
        });
    }
    getToken();
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     getSummaryTerminal();
  //     getSummaryKendaraanArteri();
  //     getSummaryKendaraanDalkot();
  //     getSummaryKendaraanTol();
  //     getSummaryPelabuhan();
  //     getSummaryPenerbangan();
  //     getSummaryRampCheck();
  //     getSummaryKAJJ();
  //     getSummaryAUM();
  //   }
  // }, [token]);

  return (
    <div>
      <ContentHeader title="Dashboard Angkutan Lebaran" />
      <div className="row">
        <div className="card card-outline card-primary" style={{ width: '100%', height: '100vh' }}>
          <div className="card-header">
            <h3 className="card-title">Angkutan Lebaran 2024</h3>
          </div>
          <div className="card-body" >
            <Angleb2024 />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary" style={{ width: '100%', height: '100vh' }}>
          <div className="card-header">
            <h3 className="card-title">Angkutan Lebaran 2023</h3>
            {/* <div className="card-tools">
              <ul className="nav nav-pills ml-auto">
                <li className="nav-item">
                  <a href="/DetailAngleb2023">
                    <button
                      type="button"
                      className="btn btn-block bg-gradient-info btn-md"
                    >
                      Detail Summary
                    </button>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="card-body">
            <Angleb2023 />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="card card-outline card-primary" style={{ width: '100%', height: '100vh' }}>
          <div className="card-header">
            <h3 className="card-title">Angkutan Lebaran 2022</h3>
          </div>
          <div className="card-body" >
            <Angleb2022 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataproduksiTerminal;
