import React, { useEffect, Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { calculateWindowSize } from '@app/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowSize } from '@app/store/reducers/ui';

import Dashboard from '@pages/Dashboard';
import Modal from '@app/pages/Modal';
import Blank from '@pages/Blank';
import Setting from '@pages/Setting';
import SubMenu from '@pages/SubMenu';
import Profile from '@pages/profile/Profile';
import DataproduksiTerminal from './pages/AngkutanLebaran/dataproduksiTerminal';
import DetailAngleb2023 from './pages/AngkutanLebaran/detailAngleb2023';
import DashboardNataru from './pages/AngkutanNataru/DashboardNataru';
import DashboardBLL from './pages/BLL/DashboardBLL';
import DashboardBKA from './pages/BKA/DashboardBKA';
import DashboardBPP from './pages/BPP/DashboadBPP';
import DashboardSudinJB from './pages/SudinJakbar/DashboardSudinJB';
import DashboardSudinJP from './pages/SudinJakpus/DashboardSudinJP';
import DashboardSudinJS from './pages/SudinJaksel/DashboardSudinJS';
import DashboardSudinJU from './pages/SudinJakut/DashboardSudinJU';
import DashboardUPAP from './pages/UPAP/DashboardUPAP';
import DashboardUPAS from './pages/UPAS/DashboardUPAS';
import PKB_Angke from './pages/UPKB/PKB_Angke';
import PKB_Cilincing from './pages/UPKB/PKB_Cilincing';
import PKB_Jagakarsa from './pages/UPKB/PKB_Jagakarsa';
import PKB_Pulogadung from './pages/UPKB/PKB_Pulogadung';
import PKB_Ujungmenteng from './pages/UPKB/PKB_Ujungmenteng';
import DashboardUPparkir from './pages/UPPARKIR/DashboardUPparkir';
import DashboardUPPD from './pages/UPPD/DashboardUPPD';
import DashboardUPSJBE from './pages/UPSJBE/DashboardUpsjbe';
import DashboardUPTAJ from './pages/UPTAJ/DashboardUPTAJ';
import DashboardSPLL from './pages/UPSPLL/DashboarsSPLL';
import UPTAJ_documentPendaftaran from './pages/UPTAJ/UPTAJ_documentPendaftaran';
import UPTAJ_documentPerpanjangan from './pages/UPTAJ/UPTAJ_documentPerpanjangan';
import UPTAJ_documentKadaluarsa from './pages/UPTAJ/UPTAJ_documentKadaluarsa';
import UPTAJ_formPendaftaran from './pages/UPTAJ/UPTAJ_formPendaftaran';
import UPTAJ_formPerpanjangan from './pages/UPTAJ/UPTAJ_formPerpanjangan';
import UPTAJ_permKadaluarsa from './pages/UPTAJ/UPTAJ_permKadaluarsa';
import UPTAJ_permPendaftaran from './pages/UPTAJ/UPTAJ_permPendaftaran';
import UPTAJ_permPerpanjangan from './pages/UPTAJ/UPTAJ_permPerpanjangan';
import UPTAJ_petunjukPengguna from './pages/UPTAJ/UPTAJ_petunjukPengguna';
import Lrt from './pages/BAJ/Lrt';
import Mrt from './pages/BAJ/Mrt';
import Transjakarta from './pages/BAJ/Transjakarta';
import Jaklingko from './pages/JLI/jaklingko';
import DataPegawai from './pages/Sekretariat/dataPegawai';
import PenumpangPelabuhan from './pages/UPPD/PenumpangPelabuhan';
import DataAgregatPenumpang from './pages/BAJ/DatapenumpangKeseluruhan';
import DataPenumpang from './pages/UPTAJ/DataPenumpang';
import FileUpload from './pages/DALLOPS/fileUpload';
import PetaLokasiTerminal from './pages/UPTAJ/PetaLokasiTerminal';
import PetaAPILL from './pages/UPSPLL/PetaAPILL';
import GeoLocation from './pages/DALLOPS/GeoLocation';
import SensorTraffic from './pages/UPSPLL/SensorTraffic';
import JumlahKendaraan from './pages/UPSPLL/JumlahKendaraan';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import DataTindakDallops from './pages/DALLOPS/tindakDallops';
import DataTindakJakbar from './pages/DALLOPS/tindakJakbar';
import DataTindakJakpus from './pages/DALLOPS/tindakJakpus';
import DataTindakJaksel from './pages/DALLOPS/tindakJaksel';
import DataTindakJaktim from './pages/DALLOPS/tindakJaktim';
import DataTindakJakut from './pages/DALLOPS/tindakJakut';
import DataTindakSummary from './pages/DALLOPS/tindakSummary';
import DashboardHBKB from './pages/UPSPLL/DashboarsHBKB';
import DashboardBongkarMuat from './pages/DashboardBongkarMuat';
import { Settings } from 'luxon';

class App extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      data: false
    };
  }

  // callAPI() {
  //   fetch("https://portaldata.jaktraffic.my.id")
  //       .then(res => res.text())
  //       .then(res => this.setState({ apiResponse: res }));
  // }

  // componentDidMount() {
  //   let url ="https://portaldata.jaktraffic.my.id/APIS/UPPD/all/1000";
  //   fetch(url,{
  //     method:'GET',
  //     headers:{
  //       'Accept':'application/json',
  //       'Content-Type':'application/json',
  //     }
  //   }).then((result)=>{
  //     result.json().then((resp)=>{
  //       console.warn(resp);
  //     })
  //   })
  // }
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/register" element={<PublicRoute />}>
            <Route path="/register" element={<Register />} />
          </Route>
          <Route path="/forgot-password" element={<PublicRoute />}>
            <Route path="/forgot-password" element={<ForgetPassword />} />
          </Route>
          <Route path="/recover-password" element={<PublicRoute />}>
            <Route path="/recover-password" element={<RecoverPassword />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Main />}>
              <Route path="/sub-menu-2" element={<Blank />} />
              <Route path="/sub-menu-1" element={<SubMenu />} />
              <Route path="/blank" element={<Blank />} />
              <Route path="/Setting" element={<Setting />} />
              <Route path="/Modal" element={<Modal />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/dataproduksiterminal" element={<DataproduksiTerminal />} />
              <Route path="/DetailAngleb2023" element={<DetailAngleb2023 />} />
              <Route path="/DashboardNataru" element={<DashboardNataru />} />
              <Route path="/lrt" element={<Lrt />} />
              <Route path="/mrt" element={<Mrt />} />
              <Route path="/transjakarta" element={<Transjakarta />} />
              <Route path="/DashboardBLL" element={<DashboardBLL />} />
              <Route path="/DashboardBKA" element={<DashboardBKA />} />
              <Route path="/DashboardBPP" element={<DashboardBPP />} />
              <Route path="/DashboardSudinJB" element={<DashboardSudinJB />} />
              <Route path="/DashboardSudinJP" element={<DashboardSudinJP />} />
              <Route path="/DashboardSudinJS" element={<DashboardSudinJS />} />
              <Route path="/DashboardSudinJU" element={<DashboardSudinJU />} />
              <Route path="/DashboardUPAP" element={<DashboardUPAP />} />
              <Route path="/DashboardUPAS" element={<DashboardUPAS />} />
              <Route path="/PKB_Angke" element={<PKB_Angke />} />
              <Route path="/PKB_Cilincing" element={<PKB_Cilincing />} />
              <Route path="/PKB_Jagakarsa" element={<PKB_Jagakarsa />} />
              <Route path="/PKB_Pulogadung" element={<PKB_Pulogadung />} />
              <Route path="/PKB_Ujungmenteng" element={<PKB_Ujungmenteng />} />
              <Route path="/DashboardUPparkir" element={<DashboardUPparkir />} />
              <Route path="/DashboardUPPD" element={<DashboardUPPD />} />
              <Route path="/DashboardUPSJBE" element={<DashboardUPSJBE />} />
              <Route path="/DashboardSPLL" element={<DashboardSPLL />} />
              <Route path="/DashboardHBKB" element={<DashboardHBKB />} />
              <Route path="/DashboardBongkarMuat" element={<DashboardBongkarMuat />} />
              <Route path="/PetaAPILL" element={<PetaAPILL />} />
              <Route path="/PetaLokasiTerminal" element={<PetaLokasiTerminal />} />
              <Route path="/DashboardUPTAJ" element={<DashboardUPTAJ />} />
              <Route path="/penumpangTerminal" element={<DataPenumpang />} />
              <Route path="/documentPendaftaranUPTAJ" element={<UPTAJ_documentPendaftaran />} />
              <Route path="/documentPerpanjanganUPTAJ" element={<UPTAJ_documentPerpanjangan />} />
              <Route path="/documentKadaluarsaUPTAJ" element={<UPTAJ_documentKadaluarsa />} />
              <Route path="/formPendaftaranUPTAJ" element={<UPTAJ_formPendaftaran />} />
              <Route path="/formPerpanjanganUPTAJ" element={<UPTAJ_formPerpanjangan />} />
              <Route path="/permPerpanjanganUPTAJ" element={<UPTAJ_permPerpanjangan />} />
              <Route path="/permPendaftaranUPTAJ" element={<UPTAJ_permPendaftaran />} />
              <Route path="/permKadaluarsaUPTAJ" element={<UPTAJ_permKadaluarsa />} />
              <Route path="/petunjukPenggunaUPTAJ" element={<UPTAJ_petunjukPengguna />} />
              <Route path="/jaklingko" element={<Jaklingko />} />
              <Route path="/dataPegawai" element={<DataPegawai />} />
              <Route path="/penumpangPelabuhan" element={<PenumpangPelabuhan />} />
              <Route path="/agregatPenumpang" element={<DataAgregatPenumpang />} />
              <Route path="/fileUpload" element={<FileUpload />} />
              <Route path="/geolocation" element={<GeoLocation />} />
              <Route path="/sensorTraffic" element={<SensorTraffic />} />
              <Route path="/jumlahKendaraan" element={<JumlahKendaraan />} />
              <Route path="/tindakDallops" element={<DataTindakDallops />} />
              <Route path="/tindakJakut" element={<DataTindakJakut />} />
              <Route path="/tindakJakbar" element={<DataTindakJakbar />} />
              <Route path="/tindakJakpus" element={<DataTindakJakpus />} />
              <Route path="/tindakJaksel" element={<DataTindakJaksel />} />
              <Route path="/tindakJaktim" element={<DataTindakJaktim />} />
              <Route path="/tindakSummary" element={<DataTindakSummary />} />
            </Route>
          </Route>
        </Routes>
        <ToastContainer
          autoClose={3000}
          draggable={false}
          position="top-right"
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />
      </BrowserRouter>
    );
  };
}




// const App = () => {

//   const windowSize = useWindowSize();
//   const screenSize = useSelector((state: any) => state.ui.screenSize);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const size = calculateWindowSize(windowSize.width);
//     if (screenSize !== size) {
//       dispatch(setWindowSize(size));
//     }
//   }, [windowSize]);

//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/login" element={<PublicRoute />}>
//           <Route path="/login" element={<Login />} />
//         </Route>
//         <Route path="/register" element={<PublicRoute />}>
//           <Route path="/register" element={<Register />} />
//         </Route>
//         <Route path="/forgot-password" element={<PublicRoute />}>
//           <Route path="/forgot-password" element={<ForgetPassword />} />
//         </Route>
//         <Route path="/recover-password" element={<PublicRoute />}>
//           <Route path="/recover-password" element={<RecoverPassword />} />
//         </Route>        
//         <Route path="/" element={<PrivateRoute />}>
//           <Route path="/" element={<Main />}>
//             <Route path="/sub-menu-2" element={<Blank />} />
//             <Route path="/sub-menu-1" element={<SubMenu />} />
//             <Route path="/blank" element={<Blank />} />
//             <Route path="/Setting" element={<Setting />} />
//             <Route path="/Modal" element={<Modal />} />
//             <Route path="/profile" element={<Profile />} />
//             <Route path="/" element={<Dashboard />} />
//             <Route path="/DashboardUPTAJ" element={<DashboardUPTAJ />} />
//             <Route path="/DashboardUPPD" element={<DashboardUPPD />} />
//             <Route path="/formPendaftaranUPTAJ" element={<UPTAJ_formPendaftaran />} />
//             <Route path="/formPerpanjanganUPTAJ" element={<UPTAJ_formPerpanjangan />} />
//             <Route path="/permPerpanjanganUPTAJ" element={<UPTAJ_permPerpanjangan />} />
//             <Route path="/permPendaftaranUPTAJ" element={<UPTAJ_permPendaftaran />} />
//             <Route path="/permKadaluarsaUPTAJ" element={<UPTAJ_permKadaluarsa />} />
//             <Route path="/petunjukPenggunaUPTAJ" element={<UPTAJ_petunjukPengguna />} />
//           </Route>
//         </Route>
//       </Routes>
//       <ToastContainer
//         autoClose={3000}
//         draggable={false}
//         position="top-right"
//         hideProgressBar={false}
//         newestOnTop
//         closeOnClick
//         rtl={false}
//         pauseOnHover
//       />
//     </BrowserRouter>
//   );
// };

export default App;
