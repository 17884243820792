import { SmallBox } from "@app/components";
import React, { useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import axios from 'axios';

const API_URL = 'https://portaldata.jaktraffic.my.id/APIS/UPTAJ/kios/all/1000';

function DashboardUPTAJ() {
  const [kios, setKios] = useState('');

  async function getKios() {
    try {
      const response = await axios.get('https://portaldata.jaktraffic.my.id/APIS/UPTAJ/kios/all/1000')
      setKios(response.kios);
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getKios();
  }, []);


  return (
    <div>
      <ContentHeader title="Dashboard E-KIOS UPTAJ" />
      <div className="row">
        <div className="col-md-3">
          <a href="/permPendaftaranUPTAJ">
            <button
              type="button"
              className="btn btn-block bg-gradient-info btn-md"
            >
              Permohonan Pendaftaran
            </button>
          </a>
        </div>
        <div className="col-md-3">
          <a href="/permPerpanjanganUPTAJ">
            <button
              type="button"
              className="btn btn-block bg-gradient-info btn-md"
            >
              Permohonan Perpanjangan
            </button>
          </a>
        </div>
        <div className="col-md-3">
          <a href="/permKadaluarsaUPTAJ">
            <button
              type="button"
              className="btn btn-block bg-gradient-info btn-md"
            >
              Permohonan Kadaluarsa
            </button>
          </a>
        </div>
        <div className="col-md-3">
          <a href="/petunjukPenggunaUPTAJ">
            <button
              type="button"
              className="btn btn-block bg-gradient-primary btn-md"
            >
              Petunjuk Pengguna
            </button>
          </a>
        </div>
      </div>

      <div className="card card-outline card-primary mt-2">
        <div className="card-header">
          <h3 className="card-title"></h3>
        </div>
        <div className="card-body">
          <table
            id="table_id"
            className="table table-head-fixed table-bordered table-responsive "
          >
            <thead>
              <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                <th>No.</th>
                <th>Nama</th>
                <th>Terminal</th>
                <th>Telepon</th>
                <th>Tanggal Pengajuan</th>
                <th>Status</th>
                <th>Validasi Staff Operasional</th>
                <th>Tanggal Validasi</th>
                <th>Validasi Kepala Unit</th>
                <th>Tanggal Validasi</th>
                <th>Dokumen</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "13px" }}>
              <tr>
                <td>1</td>
                <td>Dwintha zahrianthy</td>
                <td>Terminal Bus Tanjung Priok</td>
                <td>081242289911</td>
                <td>2023-02-10</td>
                <td>Terkirim</td>
                <td>Disetujui</td>
                <td>2023-02-24</td>
                <td>Disetujui</td>
                <td>2023-02-25</td>
                <td>
                  <a href="/documentPerpanjanganUPTAJ">
                    <button className="btn btn-block btn-default btn-xs">
                      <img
                        src="./img/view.png"
                        alt="iconView"
                        style={{ width: "25px" }}
                      />
                    </button>
                  </a>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>NGUDIYANA</td>
                <td>Terminal Bus Tanjung Priok</td>
                <td>081286020495</td>
                <td>2023-02-15</td>
                <td>Belum Terkirim</td>
                <td>Belum Validasi</td>
                <td></td>
                <td>Belum Validasi</td>
                <td></td>
                <td>
                  <a href="/formPerpanjanganUPTAJ">
                    <button className="btn btn-block btn-default btn-xs">
                      <img
                        src="./img/view.png"
                        alt="iconView"
                        style={{ width: "25px" }}
                      />
                    </button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DashboardUPTAJ;
