import { SmallBox } from "@app/components";
import React from "react";
import { ContentHeader } from "@components";

const UPTAJ_permKadaluarsa = () => {
  return (
    <div>
      <ContentHeader title="Data Permohonan Kadaluarsa" />
      <div className="row mt-3">
        <div className="col-md-11">
          <div className="buttonBack">
            <a href="/DashboardUPTAJ">
              <button
                type="button"
                className="btn bg-gradient btn-secondary btn-sm"
              >
                Kembali
              </button>
            </a>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-9">
          <div className="dataTables_length" id="example1_length">
            <label>
              Show
              <select
                name="example1_length"
                aria-controls="example1"
                className="form-control input-sm"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{" "}
              entries
            </label>
          </div>
        </div>
        <div className="col-sm-3">
          <div id="example1_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                type="search"
                className="form-control input-sm"
                placeholder=""
                aria-controls="example1"
              />
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-outline card-primary mt-2">
            <div className="card-header">
              <h3 className="card-title"></h3>
            </div>
            <div className="card-body">
              <table
                id="table_id"
                className="table table-head-fixed table-bordered table-responsive "
              >
                <thead>
                  <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                    <th>No.</th>
                    <th>Nama</th>
                    <th>Terminal</th>
                    <th>Telepon</th>
                    <th>Tanggal Pengajuan</th>
                    <th>Status</th>
                    <th>Validasi Staff Operasional</th>
                    <th>Tanggal Validasi</th>
                    <th>Validasi Kepala Unit</th>
                    <th>Tanggal Validasi</th>
                    <th>Dokumen</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "13px" }}>
                  <tr>
                    <td>1</td>
                    <td>Dwintha zahrianthy</td>
                    <td>Terminal Bus Tanjung Priok</td>
                    <td>081242289911</td>
                    <td>2023-02-10</td>
                    <td>Terkirim</td>
                    <td>Belum Validasi</td>
                    <td></td>
                    <td>2023-02-24</td>
                    <td></td>
                    <td>
                      <a href="/documentKadaluarsaUPTAJ">
                        <button className="btn btn-block btn-default btn-xs">
                          <img
                            src="./img/view.png"
                            alt="iconView"
                            style={{ width: "25px" }}
                          />
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>NGUDIYANA</td>
                    <td>Terminal Bus Tanjung Priok</td>
                    <td>081286020495</td>
                    <td>2023-02-15</td>
                    <td>Belum Terkirim</td>
                    <td>Belum Validasi</td>
                    <td></td>
                    <td>2023-02-25</td>
                    <td></td>
                    <td>
                      <a href="/documentKadaluarsaUPTAJ">
                        <button className="btn btn-block btn-default btn-xs">
                          <img
                            src="./img/view.png"
                            alt="iconView"
                            style={{ width: "25px" }}
                          />
                        </button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card-footer clearfix">
              <ul className="pagination pagination-sm m-0 float-right">
                <li className="page-item">
                  <a className="page-link" href="#">
                    «
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    »
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UPTAJ_permKadaluarsa;
