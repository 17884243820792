import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function PKB_Jagakarsa(props: any) {
  // const [token, setToken] = useState('');
  

  return (
    <div>
      <ContentHeader title="Dashboard UPKB Jagakarsa" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title"> Data Belum Tersedia</h3>
        </div>
        <div className="card-body">
        </div>
      </div>
    </div>
  );
}

export default PKB_Jagakarsa;
