import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";
import { token, tokenAuth } from '@app/store/reducers/tokenAuth';



function PenumpangPelabuhan(props: any) {
  // const [token, setToken] = useState('');
  // const [uppd, setUppd] = useState([]);

  const DataPelabuhan = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/ae016be2-8951-4a55-8e9b-ae3933f16651/page/dUESD"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    );
  };


  return (
    <div>
      <ContentHeader title="Data Penumpang Pelabuhan" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title"></h3>
        </div>
        <div className="card-body">
          <DataPelabuhan />
        </div>
      </div>
    </div>
  );
}

export default PenumpangPelabuhan;
