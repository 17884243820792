import { SmallBox } from "@app/components";
import React, { useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import axios from 'axios';
import { getIn } from "formik";


function Jaklingko(props: any) {
  const [jli, setJli] = useState([]);
  // const url = 'https://portaldata.jaktraffic.my.id/pqs/jli/feed/viewall';


  // const getJli = async () => {
  //   const url = 'https://portaldata.jaktraffic.my.id/pqs/jli/feed/viewall'; // Ganti dengan URL yang sesuai dengan API yang ingin Anda akses

  //   const body = JSON.stringify({
  //     startDate: '2023-05-01',
  //     endDate: '2023-05-02'
  //   });

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: body
  //   };

  //   try {
  //     const response = await axios.get(url, requestOptions);
  //     const data = await response.data.Info();

  //     // Memproses data yang diperoleh
  //     console.log(data.Info);
  //     // Output: {
  //     //   StartDate: "2023-05-01",
  //     //   EndDate: "2023-05-02",
  //     //   AvgDailyTrip: 1648,
  //     //   TripTJ: 861,
  //     //   TripLRT: 2,
  //     //   TripMRT: 785,
  //     //   Revenue: "Rp 7.624.036,42"
  //     // }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  // console.log(getJli());
  //   useEffect(() => {
  //       getJli();
  //   }, []);
  const Jli = () => {
    return (
      <div>
        <iframe
          width="100%"
          height="600"
          src="https://lookerstudio.google.com/embed/reporting/341313d1-93d8-4223-b163-4fea30a854a5/page/LIoRD"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen
        />
      </div>
    )

  };

  return (
    <div>

      <ContentHeader title="Data Jaklingko Indonesia" />
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title"></h3>
        </div>
        <div className="card-body">
          <Jli />
        </div>
      </div>
      {/* <div className="row">
      <div className="card card-outline card-primary col-12">
        <div className="card-header">  
        </div>
        <div className="card-body">
          <div className="row">
          <div className="form-group col-12">
            <label>Date range:</label>
            <div className="input-group">
            <div className="input-group-prepend">
            <span className="input-group-text">
            <i className="far fa-calendar-alt"></i>
            </span>
            </div>
            <input type="text" className="form-control float-right" id="reservation"/>
            </div>
          </div>
          </div>
          <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-primary float-right">
              Search
            </button>
          </div>
          </div>
        </div>
      </div>
      
      </div>
      <div className="row">
        <div className="col-6">

          <div className="small-box bg-danger">
          <div className="inner">
          <h3>65</h3>
          <p>Average Daily Trip</p>
          </div>
          <div className="icon">
          <i className="ion ion-pie-graph"></i>
          </div>
          </div>
        </div>
          <div className="col-6">

          <div className="small-box bg-danger">
          <div className="inner">
          <h3>65</h3>
          <p>Revenue</p>
          </div>
          <div className="icon">
          <i className="ion ion-pie-graph"></i>
          </div>
          </div>
          </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-6">
        <div className="small-box bg-info">
        <div className="inner">
        <h3>150</h3>
        <p>Total Trip Transjakarta</p>
        </div>
        <div className="icon">
        <i className="ion ion-bag"></i>
        </div>
        
        </div>
        </div>

        <div className="col-lg-4 col-6">

        <div className="small-box bg-success">
        <div className="inner">
        <h3>53</h3>
        <p>Total Trip MRT</p>
        </div>
        <div className="icon">
        <i className="ion ion-stats-bars"></i>
        </div>
        </div>
        </div>

        <div className="col-lg-4 col-6">

        <div className="small-box bg-warning">
        <div className="inner">
        <h3>44</h3>
        <p>Total Trip LRT</p>
        </div>
        <div className="icon">
        <i className="ion ion-person-add"></i>
        </div>
        
        </div>
        </div>
      </div> */}
      {/* <div className="row">
      <div className="card card-outline card-primary">
        <div className="card-header">
          <h3 className="card-title"></h3>
        </div>
        <div className="card-body">

          <table
            id="table_id"
            className="table table-head-fixed table-bordered table-responsive table-bordered dataTable dtr-inline"
          >
            <thead>
              <tr style={{ fontSize: "14px", justifyContent: "center" }}>
                <th>Id</th>
                <th>Pto code</th>
                <th>Transaction Valid Amount</th>
                <th>Origin Stop Code</th>
                <th>Station Orign</th>
                <th>Time Stamp Origin</th>
                <th>Origin longitude</th>
                <th>Origin latitude</th>
                <th>Destination stop code</th>
                <th>Station destination</th>
                <th>Time stamp destination</th>
                <th>Destination longitude</th>
                <th>Destination latitude</th>
                <th>Current_settle date</th>
                <th>Payment_channelid</th>
                <th>Transaction type</th>
                <th>Ticket id</th>
                <th>Transaction id</th>
                <th>Journey id</th>
                <th>Created at</th>
                <th>Scan status</th>
                <th>Qr distribution</th>
                <th>Qr used</th>
              </tr>
            </thead>
            <tbody>
            {jli.map((jakling, index) => {
              const createdAtDate = jakling.created_at ? new Date(jakling.created_at) : null;
              const formattedDate = createdAtDate ? createdAtDate.toISOString().split('T')[0] : '';
            
              return(
                <tr key={index}>
                      <td>{jakling.id !== null ? jakling.id : 0}</td>
                      <td>{jakling.pto_code !== null ? jakling.pto_code : 0}</td>
                      <td>{jakling.txn_valid_amount !== null ? jakling.txn_valid_amount : 0}</td>
                      <td>{jakling.origin_stop_code !== null ? jakling.origin_stop_code : 0}</td>
                      <td>{jakling.station_origin !== null ? jakling.station_origin : 0}</td>
                      <td>{jakling.time_stamp_origin !== null ? jakling.time_stamp_origin : 0}</td>
                      <td>{jakling.origin_lon !== null ? jakling.origin_lon : 0}</td>
                      <td>{jakling.origin_lat !== null ? jakling.origin_lat : 0}</td>
                      <td>{jakling.destination_stop_code !== null ? jakling.destination_stop_code : 0}</td>
                      <td>{jakling.station_destination !== null ? jakling.station_destination : 0}</td>
                      <td>{jakling.time_stamp_destination !== null ? jakling.time_stamp_destination : 0}</td>
                      <td>{jakling.dest_lon !== null ? jakling.dest_lon : 0}</td>
                      <td>{jakling.dest_lat !== null ? jakling.dest_lat : 0}</td>
                      <td>{jakling.current_settle_date !== null ? jakling.current_settle_date : 0}</td>
                      <td>{jakling.payment_channel_id !== null ? jakling.payment_channel_id : 0}</td>
                      <td>{jakling.txn_type !== null ? jakling.txn_type : 0}</td>
                      <td>{jakling.ticket_id !== null ? jakling.ticket_id : 0}</td>
                      <td>{jakling.txc_id !== null ? jakling.txn_id : 0}</td>
                      <td>{jakling.journey_id !== null ? jakling.journey_id : 0}</td>
                      <td>{formattedDate}</td>
                      <td>{jakling.scan_status !== null ? jakling.scan_status : 0}</td>
                      <td>{jakling.qr_distribution !== null ? jakling.qr_distribution : 0}</td>
                      <td>{jakling.qr_used !== null ? jakling.qr_used : 0}</td>
              </tr>
              );
            
            
              
            })}
              
            
            </tbody>
          </table>
        </div>
      </div>
      </div> */}
    </div>
  );
};

export default Jaklingko;
function componentDidMount() {
  throw new Error("Function not implemented.");
}

