import { SmallBox } from "@app/components";
import React, { Component, useState, useEffect } from "react";
import { ContentHeader } from "@components";
import { Link } from "react-router-dom";
import SubDash from "@pages/SubMenu";
import axios from "axios";
import { DateTime } from "luxon";

function DataPenumpang(props: any) {
    // const [token, setToken] = useState('');
    const JmlPenumpang = () => {
        return (
            <div>
                <iframe
                    width="100%"
                    height="600"
                    src="https://lookerstudio.google.com/embed/reporting/127a897b-d906-418c-867a-f5cbed345d58/page/DjPSD"
                    frameBorder="0"
                    style={{ border: '0' }}
                    allowFullScreen
                />
            </div>
        )

    };

    return (
        <div>
            <ContentHeader title="Data Penumpang Terminal" />
            <div className="card card-outline card-primary">
                <div className="card-header">
                    <h3 className="card-title"></h3>
                </div>
                <div className="card-body">
                    <JmlPenumpang />
                </div>
            </div>
        </div>
    );
}

export default DataPenumpang;
